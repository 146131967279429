.layout-footer {
    transition: margin-left 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);

    &:hover {
        background-color: var(--hover-color);
    }
}
