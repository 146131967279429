/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";


// @import "assets/sass/layout/_config";
// @import "assets/sass/layout/_content";
// @import "assets/sass/layout/_footer";
// @import "assets/sass/layout/_main";
// @import "assets/sass/layout/_menu";
// @import "assets/sass/layout/_mixins";
// @import "assets/sass/layout/_responsive";
// @import "assets/sass/layout/_splash";
// @import "assets/sass/layout/_topbar";
// @import "assets/sass/layout/_typography";
// @import "assets/sass/layout/_utils";
@import "assets/sass/layout/_layout";
@import "assets/sass/layout/_variables";
@import "assets/sass/overrides/_overrides";

@import 'material-icons/iconfont/material-icons.css';
