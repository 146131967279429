.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 4rem;
    transition: margin-left 1s;
}

.layout-main {
    flex: 1 1 auto;
}

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

:host ::ng-deep .red-background .p-dialog {
    background: #FFCDD2;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency here */
  }


//   .edit-mode-dialog {
//     background-color: lightblue; /* Change this to the desired background color */
// }

:host ::ng-deep .lightgray .p-dialog {
    background-color: lightgray;
  }


::ng-deep .selected-splitter {
    background-color: lightblue; /* Change this to the color you prefer */
}

.validation-error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 2px;
}
