.suite-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.suite-AccountantsWorld {
        background: #C8E6C9;
        color: #256029;
    }

    &.suite-Cascade {
        background: #FFCDD2;
        color: #C63737;
    }

    &.suite-Dataplan {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.suite-Elements {
        background: #B3E5FC;
        color: #23547B;
    }

    &.suite-Financials {
        background: #ECCFFF;
        color: #694382;
    }

    &.suite-FirmManagement {
        background: #FFD8B2;
        color: #805B36;
    }

    &.suite-FMP {
        background: #FFD8B2;
        color: #805B36;
    }

    &.suite-Standalone {
        background: #FFD8B2;
        color: #805B36;
    }

    &.suite-StarPM {
        background: #FFD8B2;
        color: #805B36;
    }
}


.division-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.division-Accountancy {
        background: #C8E6C9;
        color: #256029;
    }

    &.division-Americas {
        background: #FFCDD2;
        color: #C63737;
    }

    &.division-Education {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.division-HCM {
        background: #B3E5FC;
        color: #23547B;
    }
}

.tier-badge {
  border-radius: 12px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.tier-Tier0 {
    background: #06830a;
    color: #edeeed;
  }

  &.tier-Tier1 {
    background: #e60606;
    color: #edeeed;
  }

  &.tier-Tier2 {
    background: #7B3F00;
    color: #edeeed;
  }

  &.tier-Tier3 {
    background: #b1942b;
    color: #edeeed;
  }

  &.tier-Tier4 {
    background: #a1a1a1;
    color: #edeeed;
  }

  &.tier-Tier_0 {
    background: #06830a;
    color: #edeeed;
  }

  &.tier-Tier_1 {
    background: #e60606;
    color: #edeeed;
  }

  &.tier-Tier_2 {
    background: #7B3F00;
    color: #edeeed;
  }

  &.tier-Tier_3 {
    background: #b1942b;
    color: #edeeed;
  }

  &.tier-Tier_4 {
    background: #a1a1a1;
    color: #edeeed;
  }
}

.product-badge {
  border-radius: 12px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
  margin-right: 3px;

  &.producttype-product {
    background: #3B82F6;
    color: #edeeed;
  }

  &.producttype-unknown {
    background: #DCDCDC;
    color: #858b94;
  }
}

  .sdlcrequeststatus-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .3px;
    text-align: center;

    &.sdlc-Approved {
      background: #03b109;
      color: white;
    }

    &.sdlc-Requested {
      background: #636863;
      color: white;
    }

    &.sdlc-Declined {
      background: #c0280d;
      color: white;
    }
  }

  .arbstatus-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .3px;
    width: 150px;
    text-align: center;

    &.arb-Approved {
      background: #03b109;
      color: white;
    }

    &.arb-Deferred {
      background: #636863;
      color: white;
    }

    &.arb-Postponed {
      background: #636863;
      color: white;
    }

    &.arb-Rejected {
      background: #c0280d;
      color: white;
    }

    &.arb-Pending {
      background: #7b7d05;
      color: white;
    }
  }

  .arbactionstatus-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .3px;
    width: 150px;
    text-align: center;

    &.arbaction-Completed {
      background: #03b109;
      color: white;
    }

    &.arbaction-Cancelled {
      background: #636863;
      color: white;
    }

    &.arbaction-Abandoned {
      background: #636863;
      color: white;
    }

    &.arbaction-On_Hold {
      background: #c0280d;
      color: white;
    }

    &.arbaction-Pending {
      background: #7b7d05;
      color: white;
    }
  }

  .hostingprovider-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.provider-AWS {
      background-color: white;
      border: 1px solid gray;
      color: #FF9900;
      background-image: url('../../layout/images/badgelogos/aws.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 20px 16px;
      padding-left: 40px;
    }

    &.provider-Azure {
      background-color: #088DD7;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/azure.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 20px 16px;
      padding-left: 32px;
    }

    &.provider-Rackspace {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/rackspace.svg');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 20px 16px;
      padding-left: 32px;
    }
  }

  .authMechanism-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.auth-Okta {
      background-color: white;
      border: 1px solid gray;
      color: #FF9900;
      background-image: url('../../layout/images/badgelogos/okta.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 20px 16px;
      padding-left: 40px;
    }

    &.auth-Cognito {
      background-color: #088DD7;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/cognito.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 20px 16px;
      padding-left: 32px;
    }

  }

  .techdistribution-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: lightcoral;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.distro-Cloud {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #00AFF0;
      background-image: url('../../layout/images/badgelogos/cloud.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.distro-Mobile {
      background-color: #2D830E;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/mobile.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.distro-Desktop {
      background-color: #C50B40;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/desktop.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.distro-Hosted {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #004667;
      background-image: url('../../layout/images/badgelogos/hosted.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.distro-Intranet {
      background-color: #BF9C09;
      border: 1px solid gray;
      color: #FEFAE8;
      background-image: url('../../layout/images/badgelogos/intranet.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .techplatform-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: lightcoral;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.platform-Windows {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #00AFF0;
      background-image: url('../../layout/images/badgelogos/windows.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.platform-Linux {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #FF9731;
      background-image: url('../../layout/images/badgelogos/linux.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.platform-Android {
      background-color: #365D80;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/android.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.platform-iOS {
      background-color: #000000;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/ios.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.platform-OSX {
      background-color: #000000;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/ios.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .techstack-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: lightcoral;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.stack-NET {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #1383C7;
      background-image: url('../../layout/images/badgelogos/dotnet.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-NET_Core {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #5C2992;
      background-image: url('../../layout/images/badgelogos/dotnetcore.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-ReactJS {
      background-color: #222222;
      border: 1px solid gray;
      color: #00D8FF;
      background-image: url('../../layout/images/badgelogos/react.svg');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-React_Native {
      background-color: #222222;
      border: 1px solid gray;
      color: #00D8FF;
      background-image: url('../../layout/images/badgelogos/react.svg');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-Angular_JS {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #C4002B;
      background-image: url('../../layout/images/badgelogos/angular.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-SQL_Server {
      background-color: #F1F1F1;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/sqlserver.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-PostgreSQL {
      background-color: #F1F1F1;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/postgres.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-Node {
      background-color: #87CF30;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/node.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-JavaScript {
      background-color: #F0DB4F;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/js.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-PHP {
      background-color: #1B1F2A;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/php.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-C {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/cpp.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-Xamarin_Forms {
      background-color: #3498DB;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/xamarin.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-Xamarin_Native {
      background-color: #3498DB;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/xamarin.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-MySQL {
      background-color: #A2572E;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/mysql.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-MongoDB {
      background-color: #001E2B;
      border: 1px solid gray;
      color: #00ED64;
      background-image: url('../../layout/images/badgelogos/mongo.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-VueJs {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #34495E;
      background-image: url('../../layout/images/badgelogos/vue.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.stack-Java {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #5382A1;
      background-image: url('../../layout/images/badgelogos/java.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .techenvironments-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: lightcoral !important;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;
  }

  .sourcecontroltypes-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: olivedrab !important;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;
  }

  .sourcecontrolproviders-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.provider-GitHub {
      background-color: #1B1F23;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/github.webp');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.provider-Bitbucket {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #2584FF;
      background-image: url('../../layout/images/badgelogos/bitbucket.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.provider-Azure_DevOps {
      background-color: #0174CD;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/ado.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.provider-CodeCommit {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #4B612C;
      background-image: url('../../layout/images/badgelogos/codecommit.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.provider-GitLab {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #E24329;
      background-image: url('../../layout/images/badgelogos/gitlab.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .buildtools-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.tool-TeamCity {
      background-color: #000000;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/teamcity.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Azure_DevOps {
      background-color: #0174CD;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/ado.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Jenkins {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/jenkins.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .deploymenttools-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.tool-Azure_DevOps {
      background-color: #0174CD;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/ado.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-TeamCity {
      background-color: #000000;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/teamcity.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-OctopusDeploy {
      background-color: #0581D9;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/octopusdeploy.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-CodeDeploy {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #4B612C;
      background-image: url('../../layout/images/badgelogos/codedeploy.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Docker {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #0F95D5;
      background-image: url('../../layout/images/badgelogos/docker.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Terraform {
      background-color: #6742E5;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/terraform.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .backlogtools-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.tool-Azure_DevOps {
      background-color: #0174CD;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/ado.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Jira {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #2584FF;
      background-image: url('../../layout/images/badgelogos/jira.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Mural {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #000000;
      background-image: url('../../layout/images/badgelogos/mural.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .functiontype-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen !important;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;
  }

  .secmonitoringtool-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.tool-Guard_Duty_AWS {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #232F3E;
      background-image: url('../../layout/images/badgelogos/guardduty.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-eSentire {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #1A234E;
      background-image: url('../../layout/images/badgelogos/esentire.jpg');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Datadog_CSM_Enterprise {
      background-color: #632CA6;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/datadog.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Azure_Defender_for_Cloud_Plan_2 {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #73B82C;
      background-image: url('../../layout/images/badgelogos/azuredefender.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .vulnmngmttool-badge {
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    background-color: forestgreen;
    font-weight: 700;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;

    &.tool-Azure_Defender {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #73B82C;
      background-image: url('../../layout/images/badgelogos/azuredefender.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-Tenable {
      background-color: #142646;
      border: 1px solid gray;
      color: #FFFFFF;
      background-image: url('../../layout/images/badgelogos/tenable.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }

    &.tool-AWS_Inspector {
      background-color: #FFFFFF;
      border: 1px solid gray;
      color: #4B612C;
      background-image: url('../../layout/images/badgelogos/inspector.png');
      background-repeat: no-repeat;
      background-position: 8px center;
      background-size: 16px 16px;
      padding-left: 32px;
    }
  }

  .asset-details-tag span {
    background-color: #f0f0f0 !important;
    color: #2b2a27 !important;
    border-radius: 5px;
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    margin-right: 3px;
    margin-bottom: 1px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;
  }

.vulnerability-badge {
  border-radius: 12px;
  padding: .25em .5rem;
  text-transform: uppercase;
  background-color: forestgreen;
  font-weight: 700;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 3px;
  letter-spacing: .3px;
  display: inline-flex;
  align-items: center;
  min-height: 32px;
  vertical-align: middle;

  &.tool-Snyk {
    background-color: #FFFFFF;
    border: 1px solid gray;
    color: #73B82C;
    background-image: url('../../layout/images/badgelogos/snyk.png');
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 70px;
    padding-left: 16px;
    width: 90px;
    min-height: 32px;
  }

  &.tool-Nessus {
    background-color: #FFFFFF;
    border: 1px solid gray;
    background-image: url('../../layout/images/badgelogos/nessus.png');
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 70px;
    padding-left: 16px;
    width: 90px;
    min-height: 32px;
  }

  &.tool-Sonarqube_Enterprise {
    background-color: #FFFFFF;
    border: 1px solid gray;
    background-image: url('../../layout/images/badgelogos/sonarqube.png');
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 70px;
    padding-left: 16px;
    width: 90px;
    min-height: 32px;
  }

  &.tool-SharePoint {
    background-color: #FFFFFF;
    border: 1px solid gray;
    background-image: url('../../layout/images/badgelogos/sharepoint.png');
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 90px;
    padding-left: 16px;
    width: 110px;
    min-height: 32px;
  }

  &.tool-Lens {
    background-color: #FFFFFF;
    border: 1px solid gray;
    background-image: url('../../layout/images/iris-logo.jpg');
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 90px;
    padding-left: 16px;
    width: 110px;
    min-height: 32px;
  }

  &.tool-Tenable_WAS {
    background-color: #0174CD;
    border: 1px solid gray;
    background-image: url('../../layout/images/badgelogos/tenablewas.webp');
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 80px;
    padding-left: 16px;
    width: 100px;
    min-height: 32px;
  }

  &.severity-critical {
    background-color: #a80027;
    border: 1px solid gray;
    color: #FFFFFF;
    min-height: 32px;
  }

  &.severity-high {
    background-color: #FF0000;
    border: 1px solid gray;
    color: #FFFFFF;
    min-height: 32px;
  }

  &.severity-medium {
    background-color: #d49204;
    border: 1px solid gray;
    color: #FFFFFF;
    min-height: 32px;
  }

  &.severity-low {
    background-color: #ada85f;
    border: 1px solid gray;
    color: #FFFFFF;
    min-height: 32px;
  }

  &.age-new {
    background-color: #a327b1;
    border: 1px solid gray;
    color: #FFFFFF;
    min-height: 32px;
  }

  &.age-aged {
    background-color: #e21f1f;
    border: 1px solid gray;
    color: #FFFFFF;
    min-height: 32px;
  }
}

  .asset-details-tag-alt {
    margin-right: 3px;
  }

  .asset-details-tag-alt span {
    background-color: #2b3d30 !important;
    color: #f0f0f0 !important;
    border-radius: 5px;
    border-radius: 12px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 3px;
    letter-spacing: .3px;
    display: inline-flex;
    align-items: center;
  }

