.dashboard-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-header-title {
  width: 75%;
}

.dashboard-header-logo {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
}

.dashboard-header-logo img {
  height: 90px;
  width: auto;
}

.productdetails-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.productdetails-top-row {
  display: flex;
  flex: 1;
}

.productdetails-left-column {
  flex: 1;
  padding: 10px;
}

.productdetails-right-column {
  flex: 1;
  padding: 10px;
}

.productdetails-attribute {
  display: flex;
  margin-bottom: 7px;
}

.productdetails-label {
  font-weight: bold;
  width: 30%;
  padding-right: 10px;
}

.productdetails-value {
  width: 70%;
}

.productdetails-status-box {
  width: 100px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.productdetails-status-box.active {
  background-color: green;
}

.productdetails-status-box.inactive {
  background-color: red;
}

.productdetails-homepage-uri {
  margin-top: 10px;
}

.productdetails-bottom-row {
  padding: 10px;
}

.productdetails-description-label {
  font-weight: bold;
  margin-top: 5px;
}

.productdetails-description {
  margin-top: 5px;
}

.details-panel {
  margin-top: 10px;
}

.details-panel-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.details-panel-header-title {
  flex: 1;
}

.details-panel-header-edit-button {
  flex: 0;
  min-width: 40px;
  justify-content: right;
}

.uniform-width-input {
  width: 90%;
  min-width: 90%;
  max-width: 400px;
  box-sizing: border-box; 
}

.logo-buttons {
  margin-top: 7px;
  padding: 10px;
}

.logo-button {
  margin-right: 4px !important;
}

.edit-button-hidden {
  visibility: hidden;
}

.product-details-logo-display {
  height: 100px;
  width: auto;
}

.arb-sub-table-container {
  background-color: #f9f9f9; 
  border: 1px solid #ddd; 
  border-radius: 8px;
  padding: 10px; 
  margin-top: 10px; 
}

.arb-expand-collapse-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
}
