* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 14;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-theme-light {
    background-color: #edf1f5;
}


.suite-badge {
    &.suite-elements {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background: #FFD8B2;
        color: #805B36;
    }
}

.selected-card {
    background-color: lightblue; /* Or any other formatting you want */
}
// styles.scss

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
