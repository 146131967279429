.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  padding: 20px;
}

.widgetCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: var(--surface-border);
  border-radius: 16px; /* Increased border-radius for more rounded corners */
  background-color: var(--surface-card);
  overflow: hidden; /* Ensures the header's border-radius is visible */
}

.widgetCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--gray-400);
  padding: 20px;
  border-top-left-radius: 16px; /* Match the card's border-radius */
  border-top-right-radius: 16px; /* Match the card's border-radius */
  cursor: pointer; /* Indicates that the header is clickable */
}

.widgetCardHeader h5 {
  margin: 0;
}

.chevron {
  font-size: 20px;
}

.chevron i.chevron-down::before {
  content: '▼';
}

.chevron i.chevron-up::before {
  content: '▲';
}

.collapsed {
  height: 95px; /* Make sure the height adjusts based on content */
}


.collapsed .pieWidgetContainer,
.collapsed .metricsWidgetContainer,
.collapsed .metricsWidgetLeftSide,
.collapsed .filter-container,
.collapsed .mb-3,
.collapsed .mt-3,
.collapsed .ng-container {
  display: none; /* Ensure content is hidden when collapsed */
}


.pieWidgetContainer {
  display: flex;
  align-items: center;
  padding: 20px;
}

.pieWidgetLeft {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100%;
  width: 50%; 
}

.pieWidgetRight {
  flex: 1;
  padding-left: 20px; /* Adjust this value as needed for spacing */
  width: 100%;
}

.pieWidgetTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5px;
}

.pieWidgetCell {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.pieWidgetVulnCell {
  width: 100px;
}

.pieWidgetLicenceCell {
  width: 200px;
}

.pieWidgetCellValue {
  font-weight: bold;
  width: 100px;
}

.pieWidgetHeader {
  background-color:var(--surface-border) !important;
}

.pieWidgetcritical {
  background-color: #8B0000;
  color: white;
}

.pieWidgethigh {
  background-color: #FF4500;
  color: white;
}

.pieWidgetrequiresaction {
  background-color: #FF4500;
  color: white;
}

.pieWidgetrequiresinvestigation {
  background-color: #FFA500;
  color: white;
}

.pieWidgetlowconcern {
  background-color: #FFD700;
  color: darkslategray;
}

.pieWidgetmedium {
  background-color: #FFA500;
  color: white;
}

.pieWidgetlow {
  background-color: #FFD700;
  color: darkslategray;
}

.pieWidgetDataTable {
  border-radius: 15px;
  overflow: hidden;
}

.snykLink {
  font-size: smaller;
  text-align: center;
  margin-top: 5px;
  padding: 5px;
  width:100%;
}

.reportingLevelLabel {
    margin-left: 10px;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.filter-container {
  display: flex; /* Arrange labels horizontally */
  flex-wrap: wrap; /* Allow labels to wrap to next line */
  flex-direction: column;
  margin-right: 10px;
}

.filter-container-title {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  width: 100%;
}

.filter-container label {
  display: block;
  margin-bottom: 5px;
  font-size: smaller;
}

.filter-container input[type="checkbox"] {
  margin-right: 5px;
  }

.filter-container label > input {
  width: 35px;
  height: 15px;
}

:root {
  --critical: #8B0000;
  --high: #FF4500;
  --medium: #FFA500;
  --low: #FFD700;
  --requiresaction: #FF4500;
  --requiresinvestigation: #FFA500;
  --lowconcern: #FFD700;
  --critial-hover: #A00000;
  --high-hover: #D00000;
  --medium-hover: #B65911;
  --low-hover: #AF8F00;
  --requiresaction-hover: #D00000;
  --requiresinvestigation-hover: #B65911;
  --lowconcern-hover: #AF8F00;
}

.metricsWidgetContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.metricsWidgetLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 1rem;
}

.metrics {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.metricRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.metricLabel {
  text-align: left;
}

.metricValue {
  text-align: right;
}

.bottomMetrics {
  display: flex;
  gap: 0.5rem;
}

.metricBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 1rem;
}

.metricsWidgetRightSide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 50%;
  padding: 1rem;
}

.gradeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 1rem;
  color: white;
}

.gradeBox span {
  margin-bottom: 0.5rem;
}

.gradeBox.A {
  background-color: #28a745; /* Green */
}

.gradeBox.B {
  background-color: #8bc34a; /* Light Green */
}

.gradeBox.C {
  background-color: #ffc107; /* Yellow */
}

.gradeBox.D {
  background-color: #ff9800; /* Orange */
}

.gradeBox.E {
  background-color: #f44336; /* Red */
}

.gaugeRed {
  color: #f44336; /* Red color */
}

.gaugeGreen {
  color: #28a745; /* Green color */
}

.gaugeYellow {
  color: #ff9800; 
}

.gauge-width {
/*  width: 50% !important;
  height: 50% !important;*/

/*  canvas {
    width: 90% !important;
    height: 90% !important;
  }

  .reading-label {
    width: 90% !important;
    height: 90% !important;
  }*/
}
