.layout-sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 9rem);
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    top: 7rem;
    left: 2rem;
    transition: transform .2s, left .2s;
    background-color: var(--surface-overlay);
    border-radius: 12px;
    padding: 0.5rem 1.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menuitem {
        >a {
            display: none;
        }
    }

    .layout-menuitem-root-text {
        font-size: .857rem;
        text-transform: uppercase;
        font-weight: 500;
        color: var(--surface-900);
        margin: .75rem 0;
    }

    a {
        user-select: none;

        &.active-menuitem {
            >.layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    li.active-menuitem {
        >a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: var(--text-color);
            cursor: pointer;
            padding: .2rem 0.4rem;
            border-radius: 12px;
            transition: background-color .2s, box-shadow .2s;

            .layout-menuitem-icon {
                margin-right: .5rem;
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform .2s;
            }

            &.active-route {
                font-weight: 700;
                color: var(--primary-color);
            }

            &:hover {
                background-color: var(--surface-hover);
            }

            &:focus {
                @include focused-inset();
            }
        }

        ul {
            overflow: hidden;
            border-radius: 12px;

            li {
                a {
                    margin-left: 1rem;
                }

                li {
                    a {
                        margin-left: 2rem;
                    }

                    li {
                        a {
                            margin-left: 2.5rem;
                        }

                        li {
                            a {
                                margin-left: 3rem;
                            }

                            li {
                                a {
                                    margin-left: 3.5rem;
                                }

                                li {
                                    a {
                                        margin-left: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.scaled-image {
  height: 30px;
  width: auto;
}

.menu-logo {
    text-align: center;
    justify-content: center;
}

.menu-product-name {
  width: 100%;
  text-align: center;
  justify-content: center;
  font-size: smaller;
  font-weight: bold;
  margin-top: 5px;
}
